<ps-loading [loading]="loadingModuloLegado" [background]="true" loadingText="Inicializando sistema... Aguarde...">
  <header class="header is-not-mobile">
    <div class="header-child">
      <div class="nav-wrap d-flex flex-sm-row flex-column">
        <div class="nav-left">
          <!-- <nz-avatar [nzSize]="36" nzIcon="apartment" [nzSrc]="getPublicsoftIcon()"></nz-avatar>
          <h5 class="m-l-15 mt-2 m-r-15">PublicSoft</h5> -->
          <img [src]="getPublicsoftIcon()" class="logo-header" alt="logo publicsoft">
          <img src="./assets/images/cabecalho_elemento1.png" class="header-element-1" alt="">
          <img src="./assets/images/cabecalho_elemento2.png" class="header-element-2" alt="">
        </div>
        <div class="nav-right">
          <span class="datetext">{{city}} - {{state}}, {{ dateSelected | date: 'd MMMM y' }}</span>
          <button nz-button nzType="text">
            <i nz-icon nzType="calendar"></i>
          </button>
        </div>
      </div>
    </div>
    <div nz-row class="header-child nav-big">
      <div nz-col nzSpan="6">
        <span class="modulo" nz-tooltip [nzTooltipTitle]="'Você está no módulo: ' + modulo">
          {{ modulo }}
        </span>
      </div>
      <div nz-col nzSpan="12" class="align-self-center">
        <form class="w-100" autocomplete="off">
          <nz-form-item>
            <nz-form-control>
              <nz-input-group [nzSuffix]="suffixTemplateInfo">
                <input nz-input type="text" (input)="searchStream$.next($event.target.value)"
                  placeholder="Filtrar por Cidade" name="searchCidade" />
                <ng-template #suffixTemplateInfo>
                  <i *ngIf="(obs$ | async)?.loading; else iconElse" nz-icon nz-tooltip nzTooltipTitle="Carregando..."
                    nzType="loading"></i>
                  <ng-template #iconElse>
                    <i nz-icon nzType="search"></i>
                  </ng-template>
                </ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </form>

      </div>
      <div nz-col nzSpan="6" class="align-self-center">
        <div class="nav-wrap">
          <ul class="nav-right">
            <li>
              <a (click)="changeVisao()">
                <i class="icon-size" nz-icon [nzType]="visao === 'card' ? 'unordered-list': 'appstore'"
                  theme="outline"></i>
              </a>
            </li>
            <li>
              <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
                [nzPlacement]="'bottomRight'">
                <nz-avatar [nzIcon]="'user'" [nzSrc]="getAvatar()"></nz-avatar>
              </span>
              <nz-dropdown-menu #profile="nzDropdownMenu">
                <ul nz-menu class="p-b-15 p-t-20">
                  <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                    <div class="d-flex m-r-50">
                      <nz-avatar [nzIcon]="'user'" nzSize="large" [nzSrc]="getAvatar()"></nz-avatar>
                      <div class="m-l-10">
                        <p class="m-b-0 text-dark font-weight-semibold">
                          {{ currentUser.usuarioNome }}
                        </p>
                        <p class="m-b-0 opacity-07">{{ currentUser.usuarioCpf | CPF}}</p>
                      </div>
                    </div>
                  </li>
                  <!-- <li nz-menu-item>
                    <a
                      class="p-v-5 d-flex align-items-center justify-content-between"
                    >
                      <div>
                        <i
                          class="opacity-04 font-size-16"
                          nz-icon
                          nzType="user"
                          theme="outline"
                        ></i>
                        <span class="m-l-10">Editar Perfil</span>
                      </div>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <a
                      class="p-v-5 d-flex align-items-center justify-content-between"
                    >
                      <div>
                        <i
                          class="opacity-04 font-size-16"
                          nz-icon
                          nzType="lock"
                          theme="outline"
                        ></i>
                        <span class="m-l-10">Configurações da Conta</span>
                      </div>
                    </a>
                  </li> -->
                  <li nz-menu-item [routerLink]="'/login'">
                    <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                      <div>
                        <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                        <span class="m-l-10">Sair</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>

  <div class="header is-mobile">
    <div class="header-child">
      <div class="nav-wrap">
        <ul class="nav-left">
          <li class="d-flex align-self-center">
            <!-- <nz-avatar [nzSize]="36" [nzSrc]="'./assets/images/logo3.png'"></nz-avatar>
            <h5 class="m-l-15 mt-2 m-r-15">PublicSoft</h5> -->
            <img src="./assets/images/logo_ps_18_anos2.png" class="header-element-1--mobile" alt="">
            <!-- <img src="./assets/images/ui-element-1.png" class="header-element-1--mobile" alt=""> -->
          </li>
        </ul>
        <!-- <span class=""></span> -->
        <!-- <img src="./assets/images/logo_ps_18_anos2.png" class="header-element-1--mobile" alt=""> -->
        <!-- <img src="./assets/images/cabecalho_elemento2.png" class="header-element-2--mobile" alt=""> -->
        <ul class="nav-right">
          <!-- <li>
            <a class="pt-0 pb-0 pl-0 pr-2" (click)="logout()">
              <i class="icon-size" nz-icon nzType="logout" theme="outline"></i>
            </a>
          </li>
        </ul> -->
          <li>
            <a class="pt-0 pb-0 pl-0 pr-2" (click)="toggleDrawer()">
              <i class="icon-size" nz-icon nzType="more" theme="outline"></i>
            </a>
          </li>
          <nz-drawer [nzClosable]="true" [nzVisible]="drawer" nzPlacement="right" [nzTitle]="titleDrawer"
            (nzOnClose)="toggleDrawer()">
            <ng-template #titleDrawer>
              <span class="datetext mobile-city">{{city}} - {{state}}, {{ dateSelected | date: 'd MMMM y' }}</span>
              <div class="is-table">
                <span class="mobile-date">
                  {{ dateSelected | date: 'd MMMM y' }}
                  <a class="ml-1">
                    <i class="calendar font-size-16" nz-icon nzType="calendar" theme="outline"></i>
                  </a>
                </span>
              </div>
              <div class="is-mobile-l">
                <span class="mobile-date">
                  {{ dateSelected | date: 'dd/MM/yyyy' }}
                  <a class="ml-1">
                    <i class="calendar font-size-16" nz-icon nzType="calendar" theme="outline"></i>
                  </a>
                </span>
              </div>
            </ng-template>
            <ng-container #nzDrawerContent>
              <ul nz-menu>
                <!-- <li nz-menu-item>
                  <a class="p-v-5 d-flex align-items-center justify-content-between">
                    <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                      <span class="m-l-10">Perfil</span>
                    </div>
                  </a>
                </li>
                <li nz-menu-item>
                  <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="toggleConfig()">
                    <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                      <span class="m-l-10">Configurações da Conta</span>
                    </div>
                  </a>
                  <nz-modal [(nzVisible)]="configDrawer" nzTitle="Configurações da conta" [nzContent]="configContent"
                    [nzFooter]="null" (nzOnCancel)="toggleConfig()">
                    <ng-template #configContent>
                      <div class="pb-3 pt-3 pl-3 pr-3">
                        Configurações da conta
                      </div>
                    </ng-template>
                  </nz-modal>
                </li> -->
                <li nz-menu-item [routerLink]="'/login'">
                  <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                    <div>
                      <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                      <span class="m-l-10">Sair</span>
                    </div>
                  </a>
                </li>
              </ul>
            </ng-container>
          </nz-drawer>
        </ul>
      </div>
    </div>
    <div class="header-child d-flex justify-content-center">
      <p class="mt-2">{{ modulo }}</p>
    </div>
  </div>

  <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->

  <div class="align-self-center is-mobile p-top mx-2">
    <form class="w-100" autocomplete="off">
      <nz-form-item>
        <nz-form-control>
          <nz-input-group [nzSuffix]="suffixTemplateInfo">
            <input nz-input type="text" (input)="searchStream$.next($event.target.value)"
              placeholder="Filtrar por Cidade" name="searchCidade" />
            <ng-template #suffixTemplateInfo>
              <i *ngIf="(obs$ | async)?.loading; else iconElse" nz-icon nz-tooltip nzTooltipTitle="Carregando..."
                nzType="loading"></i>
              <ng-template #iconElse>
                <i nz-icon nzType="search"></i>
              </ng-template>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>

  <div class=" p-h-0  p-t-200 minHeight bg-gradient">
    <div class="container p-b-20">
      <div class="d-flex flex-column justify-content-center">
        <div>
          <div class="ant-card-header-title m-b-20 mx-1">
            <h2 class="text-center is-not-mobile-text text-white">Selecione o módulo que deseja acessar</h2>
            <span class="text-center is-mobile-text text-white">Selecione o módulo que deseja acessar</span>
          </div>

          <nz-spin [nzSpinning]="(obs$ | async)?.loading" [nzIndicator]="indicatorTemplate">
            <div nz-row [nzGutter]="24" class="justify-content-center mx-2" *ngIf="visao === 'card'; else lista">
              <div class="container-modules" nz-col [nzXs]="24" [nzMd]="12"
                *ngFor="let cidade of (obs$ | async)?.value">
                <nz-card>
                  <div class="d-flex flex-column align-items-center justify-content-center sistema">
                    <nz-avatar class="avatar-ug shadow-lg" [nzSize]="60" nzIcon="bank"
                      [nzSrc]="cidade.brasao"></nz-avatar>

                    <span class="d-inline-block text-truncate m-t-20 f-size" style="max-width: 150px">
                      {{cidade.nome}}
                    </span>
                  </div>

                  <nz-divider></nz-divider>

                  <div class="d-flex align-items-center justify-content-center w-100" *ngIf="cidade.unidadesGestoras">
                    <div nz-row [nzGutter]="24" class="justify-content-center w-100">
                      <div nz-col [nzXs]="12" [nzMd]="8"
                        class="d-flex flex-column align-items-center justify-content-center sistema"
                        *ngFor="let unidade of cidade.unidadesGestoras" nz-dropdown nzTrigger="click"
                        [nzDropdownMenu]="menu">
                        <nz-avatar nz-tooltip [nzTooltipTitle]="unidade.nome" nzTooltipPlacement="topLeft"
                          class="m-v-10" [nzIcon]="'apartment'" [nzSrc]="unidade.brasaoUg"></nz-avatar>
                        <span class="d-inline-block text-truncate" style="max-width: 150px">
                          {{unidade.nome}}
                        </span>

                        <nz-dropdown-menu #menu="nzDropdownMenu">
                          <div nz-row [nzGutter]="24" class="justify-content-center menu-card">
                            <div nz-col [nzXs]="12" [nzMd]="8" style="color: black"
                              class="d-flex flex-column align-items-center justify-content-center sistema"
                              *ngFor="let modulo of getModAtivos(unidade)"
                              (click)="authService.selectMod(modulo, unidade.id, cidade)">
                              <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                                [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'"
                                [nzSrc]="modulo?.icone"></nz-avatar>
                              <span class="d-inline-block text-truncate" style="max-width: 150px">
                                {{modulo.nomModulo}}
                              </span>
                            </div>

                            <!-- <div nz-col [nzXs]="12" [nzMd]="8"
                              class="d-flex flex-column align-items-center justify-content-center sistema-disable"
                              *ngFor="let modulo of getModNaoAtivos(unidade)">
                              <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                                [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'" [nzSrc]="modulo?.icone">
                              </nz-avatar>
                              <span class="d-inline-block text-truncate" style="max-width: 150px">
                                {{modulo.nomModulo}}
                              </span>
                            </div> -->

                            <div nz-col [nzXs]="12" [nzMd]="8" style="color: black"
                              class="d-flex flex-column align-items-center justify-content-center sistema"
                              (click)="changeShowModNaoAtivos(unidade)">
                              <nz-avatar class="m-v-10" [nzIcon]="unidade.showModNaoAtivos ? 'up' : 'down'" nz-tooltip
                                nzTooltipPlacement="topLeft" [nzTooltipTitle]="'Carregar mais'" [nzSrc]=""></nz-avatar>
                              <span class="d-inline-block text-truncate" style="max-width: 150px">
                                {{ unidade.showModNaoAtivos ? 'Recolher' : 'Expandir' }}
                              </span>
                            </div>
                          </div>
                        </nz-dropdown-menu>
                      </div>
                    </div>
                  </div>
                </nz-card>
              </div>
            </div>

            <ng-template #lista>
              <div *ngIf="favoritos.length">
                <nz-list nzItemLayout="horizontal" class="mx-2">
                  <nz-list-item class="list-card" *ngFor="let favorito of (fav$ | async)?.value">
                    <nz-list-item-meta [nzAvatar]="avatar">
                      <ng-template #avatar>
                        <nz-avatar [nzSize]="avatarSize" nzIcon="bank" [nzSrc]="favorito.brasao"></nz-avatar>
                      </ng-template>
                      <nz-list-item-meta-title>
                        <div class="d-flex justify-content-between">
                          <span class="f-size-list text-truncate">{{ favorito.nome }}</span>

                          <button nz-button nzType="text" nzSize="small" (click)="removeFromFavorite(favorito)">
                            <i class="iconColor" nz-icon nzType="star" nzTheme="fill"></i>
                          </button>
                        </div>
                      </nz-list-item-meta-title>
                      <nz-list-item-meta-description>
                        <nz-collapse [nzBordered]="false" [nzExpandIconPosition]="'right'">
                          <nz-collapse-panel *ngFor="let unidade of favorito.unidadesGestoras" [nzHeader]="panelTitle">
                            <ng-template #panelTitle>
                              <div class="panelTitle">
                                <nz-avatar class="m-r-10" [nzSrc]="unidade.brasaoUg" [nzSize]="32"></nz-avatar>
                                <span class="align-self-center f-size-panel-header text-truncate">{{ unidade.nome
                                  }}</span>
                              </div>
                            </ng-template>
                            <div nz-row [nzGutter]="24">
                              <div nz-col [nzXs]="8" [nzMd]="4" style="color: black"
                                class="d-flex flex-column align-items-center sistema my-3"
                                *ngFor="let modulo of getModAtivos(unidade)"
                                (click)="authService.selectMod(modulo, unidade.id, favorito, $event)">
                                <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                                  [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'"
                                  [nzSrc]="getModuloIcon(modulo)"></nz-avatar>
                                <span class="d-inline-block text-size text-truncate">
                                  {{modulo.nomModulo}}
                                </span>
                              </div>

                              <!-- <div nz-col [nzXs]="8" [nzMd]="4"
                                class="d-flex flex-column align-items-center sistema-disable my-3"
                                *ngFor="let modulo of getModNaoAtivos(unidade)">
                                <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                                  [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'"
                                  [nzSrc]="getModuloIcon(modulo)">
                                </nz-avatar>
                                <span class="d-inline-block text-truncate" style="max-width: 150px">
                                  {{modulo.nomModulo}}
                                </span>
                              </div> -->
                            </div>
                          </nz-collapse-panel>
                        </nz-collapse>
                      </nz-list-item-meta-description>
                    </nz-list-item-meta>
                  </nz-list-item>
                </nz-list>

                <nz-divider></nz-divider>
              </div>

              <nz-list nzItemLayout="horizontal" class="mx-2">
                <nz-list-item class="list-card" *ngFor="let cidade of (obs$ | async)?.value">
                  <nz-list-item-meta [nzAvatar]="avatar">
                    <ng-template #avatar>
                      <nz-avatar [nzSize]="avatarSize" nzIcon="bank" [nzSrc]="cidade.brasao"></nz-avatar>
                    </ng-template>
                    <nz-list-item-meta-title>
                      <div class="d-flex justify-content-between">
                        <span class="f-size-list text-truncate">{{ cidade.nome }}</span>

                        <button nz-button nzType="text" nzSize="small" (click)="addToFavorite(cidade)">
                          <i nz-icon nzType="star" nzTheme="outline"></i>
                        </button>
                      </div>
                    </nz-list-item-meta-title>
                    <nz-list-item-meta-description>
                      <nz-collapse [nzBordered]="false" [nzExpandIconPosition]="'right'">
                        <nz-collapse-panel *ngFor="let unidade of cidade.unidadesGestoras" [nzHeader]="panelTitle">
                          <ng-template #panelTitle>
                            <div class="panelTitle">
                              <nz-avatar class="m-r-10" [nzSrc]="unidade.brasaoUg" [nzSize]="32"></nz-avatar>
                              <span class="align-self-center f-size-panel-header text-truncate">{{ unidade.nome
                                }}</span>
                            </div>
                          </ng-template>
                          <div nz-row [nzGutter]="24">
                            <div nz-col [nzXs]="8" [nzMd]="4" style="color: black"
                              class="d-flex flex-column align-items-center sistema my-3"
                              *ngFor="let modulo of getModAtivos(unidade)"
                              (click)="handleModClick(modulo, unidade.id, cidade, $event)">
                              <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                                [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'"
                                [nzSrc]="getModuloIcon(modulo)">
                              </nz-avatar>
                              <span class="d-inline-block text-size text-truncate">
                                {{modulo.nomModulo}}
                              </span>
                            </div>

                            <!-- <div nz-col [nzXs]="8" [nzMd]="4"
                              class="d-flex flex-column align-items-center sistema-disable my-3"
                              *ngFor="let modulo of getModNaoAtivos(unidade)">
                              <nz-avatar class="m-v-10" nz-tooltip nzTooltipPlacement="topLeft"
                                [nzTooltipTitle]="modulo.nomModulo" [nzIcon]="'area-chart'"
                                [nzSrc]="getModuloIcon(modulo)">
                              </nz-avatar>
                              <span class="d-inline-block text-truncate" style="max-width: 150px">
                                {{modulo.nomModulo}}
                              </span>
                            </div> -->
                          </div>
                        </nz-collapse-panel>
                      </nz-collapse>
                    </nz-list-item-meta-description>
                  </nz-list-item-meta>
                </nz-list-item>
              </nz-list>
            </ng-template>
          </nz-spin>
        </div>
      </div>
    </div>
    <div class="d-none d-md-flex p-h-20 w-100 justify-content-between font-weight-normal">
      <span class="text-white">
        © {{ today | date: 'yyyy'}}
        <a class="text-white text-link" href="https://publicsoft.com.br" target="_blank">PublicSoft</a>
      </span>
      <ul class="list-inline list-social-media">
        <li class="list-inline-item" *ngFor="let icon of icons">
          <a *ngIf="!(icon.type === 'twitter')" [href]="icon.url" target="_blank" class="text-white m-r-15 ">
            <i nz-icon [nzType]="icon.type" nzTheme="outline" class="social-icons "></i>
          </a>
          <a *ngIf="icon.type === 'twitter'" [href]="icon.url" target="_blank" class="text-white m-r-15 ">
            <img src="../../../assets/images/x.png" width="28" height="28" class="icon-x-twitter">
          </a>
        </li>
      </ul>
      <!-- <ul class="list-inline">
        <li class="list-inline-item">
          <a class="text-gray text-link" href="">Legal</a>
        </li>
        <li class="list-inline-item">
          <a class="text-gray text-link" href="">Privacy</a>
        </li>
      </ul> -->
    </div>
  </div>

  <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->



  <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
</ps-loading>