import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PsValidationFormService } from '@ps-erp-angular/ps-ui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../environments/environment';
import { UrlService } from '../services/url-server.service';

@Component({
  selector: 'ps-acesso-page-reset-password',
  templateUrl: './ps-page-reset-password.component.html',
  styleUrls: ['./ps-page-reset-password.scss'],
})
export class PsPageResetPasswordComponent {
  @Input() teste = '';
  public today: Date = new Date();
  public model: any = {};
  public form: FormGroup = new FormGroup({});
  public isLoading = false;
  private hashRecuperarSenha: any;
  public urlConnection: string;
  fields: FormlyFieldConfig[] = [
    {
      validators: {
        validation: [
          { name: 'fieldMatch', options: { errorPath: 'passwordConfirm' } },
        ],
      },
      fieldGroup: [
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Senha',
            placeholder: 'Deve ter no minímo 6 caracteres',
            required: true,
            minLength: 6,
            attributes: {
              xs: 24,
              md: 24,
            },
          },
        },
        {
          key: 'passwordConfirm',
          type: 'input',
          templateOptions: {
            type: 'password',
            label: 'Confirmação de Senha',
            placeholder: 'Por favor, informe novamente sua senha.',
            required: true,
            attributes: {
              xs: 24,
              md: 24,
            },
          },
        },
      ],
    },
  ];

  constructor(
    private validationService: PsValidationFormService,
    private avtivateRouter: ActivatedRoute,
    private http: HttpClient,
    private navigateRouter: Router,
    private message: NzMessageService,
    private location: Location,
    urlServer: UrlService
  ) {
    this.urlConnection = urlServer.generateUrlByEnvironment(
      environment,
      '5010',
      'autorizacao-api'
    );
    this.avtivateRouter.queryParamMap.subscribe((queryParams) => {
      this.hashRecuperarSenha = queryParams.get('hr');
    });
  }

  reset(): void {
    this.form.reset();
    this.isLoading = false;
  }

  submitForm(): void {
    this.validationService.validateAllFormFields(this.form);

    this.isLoading = true;
    this.http
      .put(`${this.urlConnection}/nova-senha`, {
        senha: this.form.get('password').value,
        confirmarSenha: this.form.get('passwordConfirm').value,
        hash: this.hashRecuperarSenha,
      })
      .toPromise()
      .then((response) => {
        this.message.success(response[`message`], { nzDuration: 3500 });
        this.reset();
        this.navigateRouter.navigate(['/login']);
      })
      .catch((err) => {
        this.reset();
        this.message.error(err.error.message, { nzDuration: 3000 });
      });
  }

  back(): void {
    this.location.back();
  }
}
