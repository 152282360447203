import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { Page404Component } from './pages/page-feedbacks';
import { PsPageForgotPasswordComponent } from './pages/page-forgot-password';
import { PsPageLoginComponent } from './pages/page-login/page-login.component';
import { PsPageResetPasswordComponent } from './pages/page-reset-password/ps-page-reset-password.component';
import { PsPageSelectModuleComponent } from './pages/page-select-module/page-select-module.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'login',
        component: PsPageLoginComponent,
      },
      {
        path: 'forgot-password',
        component: PsPageForgotPasswordComponent,
      },
      {
        path: 'reset-password',
        component: PsPageResetPasswordComponent,
      },
      {
        path: 'select-module',
        component: PsPageSelectModuleComponent,
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: Page404Component,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
