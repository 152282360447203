import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PsUiModule } from '@ps-erp-angular/ps-ui';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { PsPagesModule } from './pages/pages.module';

registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NzIconModule,
    NzMessageModule,
    PsPagesModule,
    PsUiModule.forRoot({ appVersion: '1.0.0' }),
  ],
  providers: [
    CookieService,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: NZ_I18N,
      useValue: pt_BR,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
