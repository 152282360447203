import { Component, Input, OnInit, Optional } from '@angular/core';

@Component({
  selector: 'ps-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Optional()
  @Input('loadingText')
  public loadingText: string = 'Inicializando sistema... Aguarde...';
  @Input('loading') public loading: boolean = false;
  @Input('background') public background: boolean = true;
  @Optional()
  @Input('tamanho')
  public tamanho: 'micro' | 'pequeno' | 'medio' | 'grande' | 'gigante' =
    'medio';
  constructor() {}

  ngOnInit(): void {}
}
