<header class="header">

    <div class="header-child">
        <div class="nav-wrap d-flex flex-sm-row flex-column">
            <div class="nav-left justify-content-between p-h-20">
                <a [routerLink]="'/'">
                    <img src="assets/images/logo/logo.png" class="logo-visible" alt="" srcset="">
                </a>

                <a (click)="back()"><i nz-icon nzType="arrow-left" nzTheme="outline"></i> Voltar</a>
            </div>
        </div>
    </div>
</header>

<div class="page-container">
    <div class="main-content">
        <div class="container p-h-0 p-b-20 p-t-120 h-100 bg">
            <div class="d-flex flex-column justify-content-center">
                <div class="row align-items-center">
                    <div class="col-md-7 col-lg-5 m-h-auto">
                        <nz-card class="m-b-100 shadow-lg" [nzTitle]="'Redefinição de senha'">

                            <form accessible-form [form]="form" nz-form [nzLayout]="'vertical'" (submit)="submitForm()"
                                autocomplete="off">
                                <ps-form [form]="form" [fields]="fields" [model]="model"></ps-form>
                                <div class="d-flex justify-content-between">

                                    <button nz-button [nzType]="'default'" type="button" (click)="reset()">
                                        Limpar
                                    </button>
                                    <button nz-button [nzType]="'primary'" type="submit" [nzLoading]="isLoading">
                                        Enviar
                                    </button>
                                </div>
                            </form>

                        </nz-card>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>


<div class="d-none d-md-flex p-h-40 w-100 justify-content-between position-absolute font-weight-normal"
    style="bottom: 0;">
    <span class="text-gray">
        © {{ today | date: 'yyyy'}} <a class="text-gray text-link" href="https://publicsoft.com.br"
            target="_blank">PublicSoft</a>
    </span>
    <!-- <ul class="list-inline">
        <li class="list-inline-item" *ngFor="let icon of icons">
          <a [href]="icon.url" target="_blank" class="text-gray m-r-15">
            <i nz-icon [nzType]="icon.type" nzTheme="outline"></i>
          </a>
        </li>
      </ul> -->
    <ul class="list-inline">
        <li class="list-inline-item">
            <a class="text-gray text-link" href="">Legal</a>
        </li>
        <li class="list-inline-item">
            <a class="text-gray text-link" href="">Privacy</a>
        </li>
    </ul>
</div>