import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PsValidationFormService } from '@ps-erp-angular/ps-ui';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'ps-acesso-page-login',
  templateUrl: './page-login.html',
  styleUrls: ['./page-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PsPageLoginComponent implements OnInit {
  passwordVisible = false;
  password?: string;
  validateForm!: FormGroup;
  idUg: any;
  codMod: any;
  today: Date = new Date();
  returnUrl: string;
  model: any = {
    produtos: [{}],
  };
  form: FormGroup = new FormGroup({});
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  icons = [
    {
      type: 'instagram',
      url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
    },
    {
      type: 'facebook',
      url: 'https://www.facebook.com/PublicSoftOficial/',
    },
    {
      type: 'twitter',
      url: 'https://twitter.com/publicsoft',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private validationService: PsValidationFormService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public notificator: NzNotificationService
  ) {
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      this.idUg = queryParams.get('ug');
      this.codMod = queryParams.get('mod');
    });
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      login: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  reset(): void {
    this.validateForm.reset();
  }

  submitForm(): void {
    if (this.validateForm.invalid) {
      this.validationService.validateAllFormFields(this.validateForm);
      return;
    }

    this.isLoading.next(true);
    if (this.validateForm.valid) {
      this.authService.login2(this.validateForm.value).subscribe(
        async (userInfo) => {
          userInfo.cidades = userInfo.cidades.map((cidade) => {
            return {
              ...cidade,
              unidadesGestoras: cidade.unidadesGestoras.sort((a, b) =>
                a.nome.localeCompare(b.nome)
              ),
            };
          });
          userInfo.cidades.sort((a, b) => a.nome.localeCompare(b.nome));
          localStorage.setItem('app', JSON.stringify(userInfo));
          this.router.navigate(['select-module'], {
            queryParams: {
              returnUrl: this.returnUrl,
              mod: this.codMod,
              ug: this.idUg,
            },
          });
        },
        (error) => {
          this.notificator.error('Erro.', `${error.error.message}`);
          this.isLoading.next(false);
        },
        () => {
          this.isLoading.next(false);
        }
      );
    }
  }
}
