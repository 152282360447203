<div class="container-fluid p-h-0 p-v-20 h-100 bg-page-login">
  <div class="d-flex flex-column justify-content-center h-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-7 col-lg-5 m-h-auto">
          <nz-card class="m-b-100 shadow-lg card-login">
            <div class="d-flex align-items-center flex-column justify-content-between m-b-30">
              <img class="img-fluid ui-head-img" alt="" src="../../../assets/images/ui-element-1.png">

              <img class="img-fluid logo" alt="" src="../../../assets/images/logo_ps_18_anos.png">
            </div>

            <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
              <nz-form-label nzRequired>Usuário</nz-form-label>
              <nz-form-item class="m-b-1">
                <nz-form-control nzErrorTip="Por favor insira seu E-mail">
                  <nz-input-group nzPrefixIcon="user">
                    <input type="text" nz-input formControlName="login" placeholder="Usuário" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>

              <!-- ------------------------------ -->
              <nz-form-label nzRequired>Senha</nz-form-label>
              <nz-form-item class="mb-0">
                <nz-form-control nzErrorTip="Por favor insira sua senha">
                  <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                      placeholder="Senha" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #suffixTemplate>
                  <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                    (click)="passwordVisible = !passwordVisible"></i>
                </ng-template>

              </nz-form-item>
              <div class="m-b-50 ">
                <a routerLink="/forgot-password" class="login-form-forgot">Esqueceu a senha?</a>
              </div>
              <!-- ------------------------------ -->
              <!-- <ps-form [form]="form" [fields]="fields" [model]="model"></ps-form> -->
              <div class="d-flex justify-content-between">

                <button nz-button [nzType]="'default'" type="button" (click)="reset()">
                  Limpar <i nz-icon [nzType]="'clear'"></i>
                </button>
                <button nz-button [nzType]="'primary'" type="submit" [nzLoading]="isLoading | async">
                  Entrar <i nz-icon nzType="right-circle" nzTheme="outline"></i>
                </button>

              </div>
            </form>

          </nz-card>
        </div>
      </div>
    </div>
    <div class="d-none d-md-flex p-h-40 w-100 justify-content-between position-absolute font-weight-normal"
      style="bottom: 0;">
      <span class="text-white">
        © {{ today | date: 'yyyy'}} <a class="text-white text-link" href="https://publicsoft.com.br"
          target="_blank">PublicSoft</a>
      </span>
      <ul class="list-inline list-social-media">
        <li class="list-inline-item" *ngFor="let icon of icons">
          <a *ngIf="!(icon.type === 'twitter')" [href]="icon.url" target="_blank" class="text-white m-r-15 ">
            <i nz-icon [nzType]="icon.type" nzTheme="outline" class="social-icons "></i>
          </a>
          <a *ngIf="icon.type === 'twitter'" [href]="icon.url" target="_blank" class="text-white m-r-15 ">
            <img src="../../../assets/images/x.png" width="28" height="28" class="icon-x-twitter">
          </a>
        </li>
      </ul>
      <!-- <ul class="list-inline">
        <li class="list-inline-item">
          <a class="text-white text-link" href="">Legal</a>
        </li>
        <li class="list-inline-item">
          <a class="text-white text-link" href="">Privacy</a>
        </li>
      </ul> -->
    </div>
  </div>
</div>