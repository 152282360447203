<div *ngIf="loading && background"
  class="container d-flex content-center align-center loading-background f-direction-column "
  style="background-color: transparent;">
  <div>
    <img src="assets/images/logo3.png" class="logo logo--gigante logo_loading logo_loading-animation"
      alt="logo publicsoft">
  </div>
  <div>
    <h2 class="loading">{{loadingText}}</h2>
  </div>
</div>

<div *ngIf="loading && !background" class="container alfa-05">
  <img src="assets/images/logo3.png"
    class="logo logo--gigante logo_loading logo_loading-animation position-fixed position-fixed--gigante center z-top"
    alt="logo publicsoft">
</div>

<ng-content *ngIf="!loading || (loading && !background)"></ng-content>