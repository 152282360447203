import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServidorCloud, StorageService } from '@ps-erp-angular/shared';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, concat, of } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { ModuloService } from '../services/modulo.service';
import { FilterPipe } from './filter.pipe';
import { Module } from './module.interface';

import * as AutorizacaoInterfaces from '@ps-erp-angular/shared';

@Component({
  selector: 'ps-acesso-page-select-module',
  templateUrl: './page-select-module.html',
  styleUrls: ['./page-select-module.scss'],
  providers: [ModuloService],
})
export class PsPageSelectModuleComponent implements OnInit {
  state: string;
  city: string;
  public loadingModuloLegado: boolean = false;

  unidadesGestora: Array<{
    id: number;
    nome: string;
    brasao: string;
    modulos: Array<any>;
    showModNaoAtivos: boolean;
  }> = [];
  cidades: Array<{
    id: number;
    nome: string;
    unidadesGestoras: Array<any>;
  }> = [];
  favoritos: Array<{
    id: number;
    nome: string;
    unidadesGestoras: Array<any>;
  }> = [];
  modulos: Module[] = [];
  filterVisible = false;
  searchStream$ = new BehaviorSubject('');
  obs$: Observable<{
    loading: boolean;
    value?: Array<{
      id: number;
      nome: string;
      unidadesGestoras: Array<any>;
      brasao: string;
    }>;
  }>;
  fav$: Observable<{
    loading: boolean;
    value?: Array<{
      id: number;
      nome: string;
      unidadesGestoras: Array<any>;
      brasao: string;
    }>;
  }>;

  public today: Date = new Date();
  icons = [
    {
      type: 'instagram',
      url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
    },
    {
      type: 'facebook',
      url: 'https://www.facebook.com/PublicSoftOficial/',
    },
    {
      type: 'twitter',
      url: 'https://twitter.com/publicsoft',
    },
  ];

  visible = false;

  currentUser: any;
  modulo = 'ACESSO';

  visao = 'list';

  dateSelected: any = Date.now();
  dateVisible = false;

  // ----------- HEADER VARIABLES ----------- //
  drawer = false;
  modulosDrawer = false;
  configDrawer = false;
  systemDrawer = false;
  dateModal = false;
  editarPerfil = false;
  // ----------- HEADER VARIABLES ----------- //

  avatarSize: any;

  constructor(
    private moduleService: ModuloService,
    private injector: Injector,
    private router: Router,
    private storageService: StorageService,
    public authService: AuthService,
    public modalService: NzModalService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    public httpService: HttpClient
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('app'));
    this.cidades = this.currentUser.cidades;
    this.moduleService.getModules().subscribe((result) => {
      return (this.modulos = result.data.resultado);
    });

    this.getFavoritos();
    this.listarCidades();
    this.getLocation();

    this.avatarSize = window.innerWidth <= 425 ? 36 : 'large';
    this.redirectToMod();
  }

  // Função para buscar o Servidor Cloud
  private getServidorCloud(moduloId: number) {
    return this.authService.getServidorCloud$(moduloId) as Observable<{
      message: string;
      data: ServidorCloud;
    }>;
  }

  // Função para buscar os dados do módulo legado
  private getDataModuloLegado(
    modulo: AutorizacaoInterfaces.Module,
    servidorCloud: ServidorCloud,
    idUnidadeGestora?: number
  ) {
    return this.authService.getDataModuloLegado$(modulo, idUnidadeGestora).pipe(
      map(
        (response: {
          message: string;
          data: AutorizacaoInterfaces.webClienteData;
        }) => ({
          message: response.message,
          servidorCloud,
          webClient: response.data,
        })
      )
    ) as Observable<{
      message: string;
      servidorCloud: ServidorCloud;
      webClient: AutorizacaoInterfaces.webClienteData;
    }>;
  }

  // Função para buscar o link do módulo legado
  private async getLinkModuloLegado(
    moduloData: AutorizacaoInterfaces.webClienteData,
    servidorCloud: ServidorCloud,
    modulo: { splash: string; bgColor: string }
  ) {
    return (await this.authService.getLinkModuloLegado({
      webClient: moduloData[0],
      servidorCloud: servidorCloud,
      modulo,
    })) as Promise<unknown & { url: string }>;
  }

  async handleModClick(modulo, idUg: number, cidade, $event) {
    try {
      const condicaoRequestLinkModuloLegado: boolean =
        modulo.link && modulo.sistemaLegado === 'S';

      if (condicaoRequestLinkModuloLegado) {
        this.loadingModuloLegado = true;

        const servidorCloudResponse: {
          message: string;
          data: ServidorCloud;
        } = await this.getServidorCloud(modulo.idServidorCloud).toPromise();

        const response: {
          message: string;
          servidorCloud: ServidorCloud;
          webClient: AutorizacaoInterfaces.webClienteData;
        } = await this.getDataModuloLegado(
          modulo,
          servidorCloudResponse.data,
          idUg
        ).toPromise();

        const linkAcessoModuloLegado: unknown & {
          url: string;
        } = await this.getLinkModuloLegado(
          response.webClient,
          response.servidorCloud,
          modulo
        );

        window.open(linkAcessoModuloLegado?.url, '_blank');
      }

      if (modulo.codigo !== 0 && !condicaoRequestLinkModuloLegado)
        return this.authService.selectMod(modulo, idUg, cidade, $event);

      this.authService.openAdmPanel(modulo.link, $event);
    } catch (error) {
      this.authService.notification.error('Módulo Legado', error, {
        nzDuration: 6000,
      });
      setTimeout(() => {
        this.loadingModuloLegado = false;
      }, 3000);
    } finally {
      this.loadingModuloLegado = false;
    }
  }

  listarCidades(): void {
    const cidadesNaoFavoritadas = [];
    const userInfo = this.authService.getUserInfo();
    if (userInfo.adm) {
      cidadesNaoFavoritadas.push({
        id: 0,
        estado: 'ADM',
        nome: 'Geral',
        unidadesGestoras: [
          {
            brasao: 'brasao',
            estadoCidade: 'PB',
            id: 0,
            modulos: [
              {
                codigo: 0,
                id: 0,
                link: environment.panelUrl(),
                nomModulo: 'Painel Administrativo',
              },
            ],
            nomCidade: 'João Pessoa',
            nome: 'Publicsoft',
          },
        ],
      });
    }
    this.cidades.map((cidade) => {
      if (!this.favoritos.find((favorito) => favorito.nome === cidade.nome)) {
        cidadesNaoFavoritadas.push(cidade);
      }
    });
    this.obs$ = this.searchStream$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((query) => {
        return concat(
          of({ loading: true }),
          of(
            this.injector
              .get(FilterPipe)
              .transform(cidadesNaoFavoritadas, 'nome', query)
          ).pipe(
            delay(500),
            map((value) => ({ loading: false, value }))
          )
        );
      })
    );
  }

  moduloAbbreviation(modulo: Module): string {
    const result = modulo.nomModulo.trim().split(' ');
    return result[0].substr(0, 1) + result[result.length - 1].substr(0, 1);
  }

  changeVisao(): void {
    this.visao = this.visao === 'list' ? 'card' : 'list';
  }

  getModAtivos(unidadeGestora): Module[] {
    if (unidadeGestora.id === 0) {
      return unidadeGestora.modulos;
    }
    return this.modulos.filter(
      (modulo) =>
        unidadeGestora.modulos.filter((ugMod) => ugMod.codigo === modulo.codigo)
          .length > 0
    );
  }

  getModNaoAtivos(unidadeGestora): Module[] {
    if (
      (!unidadeGestora.showModNaoAtivos && this.visao === 'card') ||
      unidadeGestora.id === 0
    ) {
      return [];
    }

    const modNaoAtivos = [];
    this.modulos.map((mod) => {
      if (!unidadeGestora.modulos.find((ug) => ug.codigo === mod.codigo)) {
        modNaoAtivos.push(mod);
      }
    });
    return modNaoAtivos;
  }

  changeShowModNaoAtivos(unidadeGestora): void {
    unidadeGestora.showModNaoAtivos = !unidadeGestora.showModNaoAtivos;
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  getModuloIcon(modulo: Module): any {
    return modulo.icone ? modulo.icone : 'https://i.imgur.com/R0wxNKc.png';
  }

  getPublicsoftIcon(): any {
    const pathImage = `./assets/images/logo_ps_18_anos2.png`;
    return pathImage;
  }

  getFavoritos(): void {
    localStorage.getItem('favoritos')
      ? (this.favoritos = JSON.parse(localStorage.getItem('favoritos')))
      : localStorage.setItem('favoritos', JSON.stringify([]));

    this.fav$ = this.searchStream$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((query) => {
        return concat(
          of({ loading: true }),
          of(
            this.injector
              .get(FilterPipe)
              .transform(this.favoritos, 'nome', query)
          ).pipe(
            delay(500),
            map((value) => ({ loading: false, value }))
          )
        );
      })
    );
  }

  addToFavorite(item): void {
    this.favoritos = JSON.parse(localStorage.getItem('favoritos'));
    localStorage.removeItem('favoritos');

    this.favoritos.push(item);
    localStorage.setItem('favoritos', JSON.stringify(this.favoritos));

    this.getFavoritos();
    this.listarCidades();
  }

  getPosition(options?: PositionOptions): Promise<any> {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
  }

  async getLocation(): Promise<any> {
    let latitude: string;
    let longitude: string;
    await this.getPosition({
      enableHighAccuracy: true,
    }).then((position) => {
      latitude = String(position.coords.latitude);
      longitude = String(position.coords.longitude);
      return this.httpService
        .request(
          'GET',
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
        )
        .toPromise()
        .then((jsonObject: any) => {
          this.city = jsonObject.address.city;
          this.state = jsonObject.address.state;
        })
        .catch((err: any) => {
          throw new Error(`Falha na comunicação com serviço` + err);
        });
    });
  }

  removeFromFavorite(item): void {
    this.favoritos = JSON.parse(localStorage.getItem('favoritos'));
    localStorage.removeItem('favoritos');

    const index = this.favoritos.findIndex((obj) => obj.nome === item.nome);
    this.favoritos.splice(index, 1);
    localStorage.setItem('favoritos', JSON.stringify(this.favoritos));

    this.getFavoritos();
    this.listarCidades();
  }

  // ----------- HEADER FUNCTIONS ----------- //

  toggleDrawer(): void {
    this.drawer = !this.drawer;
  }

  toggleConfig(): void {
    this.configDrawer = !this.configDrawer;
  }

  getAvatar(): string {
    return this.cookieService.get('user-avatar');
  }

  // ----------- HEADER FUNCTIONS ----------- //

  /*
    Redirecionamento para o modulo
    Caso exsita mod(código do modulo) e ug(id da unidade gestora) na URL
  */
  redirectToMod(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.mod && params.ug) {
        const codigoModulo = Number(params.mod);
        const idUg = Number(params.ug);

        this.obs$.subscribe((result) => {
          let ug;
          let mod;
          let cidade;

          if (!!result?.value) {
            result?.value.map((cidadeMap: any) => {
              ug = cidadeMap?.unidadesGestoras
                .filter((unidadeGestora) => unidadeGestora.id === idUg)
                .shift();
              if (!!ug) {
                cidade = cidadeMap;
              }
            });
          }
          if (!!ug) {
            mod = ug.modulos
              .filter((modulo) => modulo.codigo === codigoModulo)
              .shift();
          }
          if (!!ug && !!mod && !!cidade) {
            this.authService.selectMod(mod, ug?.id, cidade, null);
          }
        });
      }
    });
  }
}
