import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class UrlService {
  // Método que irá gerar a url de conexão de acordo com o ambiente de build
  generateUrlByEnvironment(env, port = '5001', service = 'auth-api'): string {
    // if (service === 'autorizacao-api') {
    //   port = '3005';
    //   return `http://localhost:${port}/api`;
    // }
    // if (service === 'municipal-api') {
    //   port = '3010';
    //   return `http://localhost:${port}/api`;
    // }

    // if (service === 'auth') {
    //   port = '3014';
    //   return `http://localhost:${port}/api`;
    // }
    return environment.url(service);
  }
}
