import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Module } from '../page-select-module/module.interface';
import { AuthService } from './auth.service';
import { UrlService } from './url-server.service';
import { environment } from '../../../environments/environment';

export interface ReturnGetAllModules {
  message: string;
  data: {
    resultado: Module[];
  };
}

export interface Modules {
  message: string;
  data: {
    resultado: Module[];
  };
}

@Injectable()
export class ModuloService {
  constructor(
    private authService: AuthService,
    private urlService: UrlService,
  ) {}

  getModules(): Observable<ReturnGetAllModules> {
    return this.authService.http.get(
      `${this.urlService.generateUrlByEnvironment(environment, '5010', 'autorizacao-api')}/modulo/1/pesquisar-avancado?page=1&size=1000`,
      this.authService.getHeadersAuth()) as Observable<ReturnGetAllModules>;
  }

  getCidades(): Observable<any[]> {
    return of([
      { cod: 1, nome: 'Joao Pessoa', unidadesGestoras: [
        { cod: 1, nome: 'Publicsoft', brasao: '', modulos: [
          { cod: 1, nome: 'Administrativo', icon: { type: 'cluster', theme: 'outline' }},
        ]}
      ]},
      { cod: 1, nome: 'Campina Grande', unidadesGestoras: [
        { cod: 1, nome: 'Publicsoft', brasa: '', codCidade: 1, nomeCidade: 'Joao Pessoa', modulos: [
          { cod: 1, nome: 'Administrativo', icon: { type: 'cluster', theme: 'outline' }},
        ]},
        { cod: 2, nome: 'Prefeitura', brasao: '', modulos: [
          { cod: 1, nome: 'Administrativo', icon: { type: 'cluster', theme: 'outline' }},
          { cod: 2, nome: 'Doações Recebidas', icon: { type: 'file-add', theme: 'outline' }},
        ]}
      ]}
    ]);
  }
}
