import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any[] {
    if (!items) { return []; }
    if (!value) { return items; }

    return items.filter(str => {
      if (str[field] === null) { return; }
      return str[field].toString().toLowerCase().includes(value.toString().toLowerCase());
    });
  }
}
