import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Module,
  ServidorCloud,
  StorageService,
  webClienteData,
} from '@ps-erp-angular/shared';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Observer, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UrlService } from './url-server.service';

import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Injectable()
export class AuthService {
  public ugSelected: any;
  private http2: HttpClient;

  constructor(
    public http: HttpClient,
    public handler: HttpBackend,
    private storageService: StorageService,
    private cookieService: CookieService,
    private urlServe: UrlService,
    public notification: NzNotificationService,
    private router: Router
  ) {
    this.http2 = new HttpClient(handler);
  }

  // Metódo que irá logar na aplicação
  login(data: any): Observable<any> {
    return this.http.post(
      `${this.urlServe.generateUrlByEnvironment(environment)}/login`,
      data
    );
  }

  // Metódo que irá logar na aplicação
  login2(data: any): Observable<any> {
    return this.http2.post(
      `${this.urlServe.generateUrlByEnvironment(environment)}/login`,
      data
    );
  }

  getHeadersAuth(): { headers: any } {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getUserInfo().token}`,
      },
    };
  }

  public async encryptData(data: any): Promise<string> {
    return await this.postEncryptData(data)
      .then((resp) => resp)
      .catch((e) => {
        this.notification.error('Criptografia', 'Erro ao criptografar dado!');
        return 'Erro ao criptografar senha!';
      });
  }

  private postEncryptData(data: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const sub: Subscription = this.postEncryptData$(data).subscribe({
        next: ({ data }: { data: string }) => {
          resolve(data);
        },
        error: (e) => {
          reject(e);
        },
        complete() {
          sub.unsubscribe();
        },
      });
    });
  }
  private postEncryptData$(data: any): Observable<{ data: string }> {
    return this.http.post<{ data: string }>(
      `${this.urlServe.generateUrlByEnvironment(environment)}/encrypt`,
      { data },
      this.getHeadersAuth()
    );
  }

  public async decryptData(data: any): Promise<unknown> {
    return await this.postDecryptData(data)
      .then((resp) => resp)
      .catch((e) => {
        this.notification.error(
          'Criptografia',
          'Erro ao tentar descriptografar dado!'
        );
      });
  }

  private postDecryptData(data: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const sub: Subscription = this.postDecryptData$(data).subscribe({
        next: ({ data }: { data: string }) => {
          resolve(data);
        },
        error: (e) => {
          reject(e);
        },
        complete() {
          sub.unsubscribe();
        },
      });
    });
  }
  private postDecryptData$(data: any): Observable<{ data: string }> {
    return this.http
      .post<{ data: string }>(
        `${this.urlServe.generateUrlByEnvironment(environment)}/decrypt`,
        { data },
        this.getHeadersAuth()
      )
      .pipe();
  }
  public async getLinkModuloLegado({
    webClient,
    servidorCloud,
    modulo,
  }: {
    webClient: webClienteData;
    servidorCloud: ServidorCloud;
    modulo: { splash: string; bgColor: string };
  }): Promise<unknown> {
    return this.http
      .post(webClient.link, {
        user: webClient.nomeUsuarioServidor,
        password: webClient.senhaUsuarioServidor
          ? await this.decryptPassword(webClient.senhaUsuarioServidor)
          : null,
        app: webClient.nomeAppServidorWindows,
        host: servidorCloud.IPDNS,
        port: servidorCloud.portaServidor,
        urlImage: modulo.splash,
        backgroundColor: modulo.bgColor,
        dataLoginLegacy: {
          user: webClient.nomeUsuarioModulo,
          userCPF: this.getUserInfo().usuarioCpf,
          password: webClient.senhaUsuarioModulo
            ? await this.decryptPassword(webClient.senhaUsuarioModulo)
            : null,
          alias: '',
          database: '',
          managementUnit: '',
          splash: webClient.splash,
        },
      })
      .toPromise();
  }

  getDataModuloLegado$(modulo: Module, idUnidadeGestora: number) {
    return this.http.get(
      `${this.urlServe.generateUrlByEnvironment(
        null,
        '3005',
        'autorizacao-api'
      )}/modulo/modulo-legado?idModulo=${modulo.id}&cpfUsuario=${
        this.getUserInfo().usuarioCpf
      }&idUnidadeGestora=${idUnidadeGestora}`
    );
  }

  // Metódo que irá deslogar da aplicação removendo o localStorage
  logout(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.storageService.clear();
      this.cookieService.deleteAll();
      observer.next('Logout Successfully');
      observer.complete();
    });
  }

  getSistemas(ugId: number): Observable<any> {
    return this.http.get(
      `${this.urlServe.generateUrlByEnvironment(
        null,
        null,
        'autorizacao-api'
      )}/licenca/${ugId}/sistemas`
    );
  }

  getServidorCloud$(idServidorCloud: number) {
    return this.http.get(
      `${this.urlServe.generateUrlByEnvironment(
        null,
        '3010',
        'municipal-api'
      )}/servidor-cloud/get-one?idPrivado=${idServidorCloud}`
    );
  }

  async getModuloAcesso(codMod: number, idUg?: number): Promise<any> {
    return await this.getSistemas(idUg)
      .toPromise()
      .then((sistemas) => {
        return sistemas.data.filter((modulo) => modulo.codigo === codMod)[0];
      });
  }

  getUserInfo(): any {
    return JSON.parse(localStorage.getItem('app'));
  }

  redirect(cookie, link, $event) {
    const target = $event?.ctrlKey || $event?.metaKey ? '_blank' : '_self';
    return this.http2
      .post(
        `${this.urlServe.generateUrlByEnvironment(environment)}/token`,
        {
          token: encodeURIComponent(cookie),
        },
        this.getHeadersAuth()
      )
      .toPromise()
      .then((response: { message: string; data: string }) => {
        window.open(`${link}?cookie=${response.data}`, target);
      })
      .catch((errorResponse) => {
        errorResponse.error.code === 401
          ? this.notification.warning(
              'Sessão expirada!',
              'Por favor, faça login novamente!'
            )
          : this.notification.error(
              'Erro token!',
              `Erro na transferência de token: ${errorResponse.error.message}`
            ),
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 2500);
      });
  }

  openAdmPanel(link, $event) {
    //
    const userInfo = this.getUserInfo();
    this.redirect(userInfo.token, link, $event);
  }

  async selectMod(modulo, idUg: number, cidade, $event): Promise<any> {
    const codMod = modulo.cod ? modulo.cod : modulo.codigo;
    this.http
      .get(
        `${this.urlServe.generateUrlByEnvironment(
          null,
          null,
          'autorizacao-api'
        )}/modulo/1/consultar-por-parametro?field=codigo&value=${codMod}`
      )
      .toPromise()
      .then(async (result: any) => {
        const mod = result.data;
        if (!mod.link) {
          return;
        }

        const userInfo = this.getUserInfo();

        const userCidade = userInfo.cidades.filter(
          (userCid) =>
            userCid.estado === cidade.estado && userCid.nome === cidade.nome
        )[0];
        for (let i = 0; i < userCidade.unidadesGestoras.length; i++) {
          userCidade.unidadesGestoras[i].brasao = '';
        }

        const ugs = userCidade.unidadesGestoras.filter((ug) => ug.id === idUg);

        const userUgs = ugs.map((key) => {
          return {
            ...key,
            modulos: key.modulos.filter(
              (ug, index, self) =>
                index === self.findIndex((o) => o.id === ug.id)
            ),
          };
        })[0];

        const domain = environment.domain;

        this.cookieService.set(
          'user-ugs',
          JSON.stringify(userUgs),
          1,
          '/',
          domain
        );

        userUgs.modulos = userUgs.modulos.filter(
          (mod) => mod.codigo === modulo.codigo
        );

        const userUg = userUgs;

        // const primeiroAcesso = await this.http
        //   .get(
        //     `${this.urlServe.generateUrlByEnvironment(null, null, 'trace-api')}/ingress-event/?idUsuario=${
        //       userInfo.idUsuario
        //     }&aplicacaoOrigem=${codMod}`,
        //     this.getHeadersAuth()
        //   )
        //   .toPromise()
        //   .then(
        //     async (ingressEvent: { mesage: string; data: any[] }) =>
        //       ingressEvent.data.length === 0
        //   );

        this.cookieService.set(
          'user-ug',
          JSON.stringify(userUg),
          1,
          '/',
          domain
        );
        this.cookieService.set('primeiro-acesso', null, 1, '/', domain);
        this.cookieService.set(
          'user-nome',
          userInfo.usuarioNome,
          1,
          '/',
          domain
        );
        this.cookieService.set(
          'user-email',
          userInfo.usuarioEmail,
          1,
          '/',
          domain
        );
        this.cookieService.set('user-cpf', userInfo.usuarioCpf, 1, '/', domain);
        this.cookieService.set('modulo-icone', mod.icone, 1, '/', domain);
        this.cookieService.set('modulo-nome', mod.nomModulo, 1, '/', domain);
        this.cookieService.set('modulo-id', mod.id, 1, '/', domain);
        this.cookieService.set('modulo-codigo', codMod, 1, '/', domain);
        this.cookieService.set('user-id', userInfo.idUsuario, 1, '/', domain);
        this.cookieService.set(
          'user-avatar',
          userInfo.usuarioAvatar,
          1,
          '/',
          domain
        );
        this.cookieService.set('ps-token', userInfo.token, 1, '/', domain);
        const base64Cookie = btoa(JSON.stringify(this.cookieService.getAll()));
        // const target = $event?.ctrlKey || $event?.metaKey ? '_blank' : '_self';
        // window.open(`${mod.link}?cookie=${encodeURIComponent(base64Cookie)}`, target)
        this.redirect(base64Cookie, mod.link, $event);
      });
  }

  encryptPassword(password: string): Promise<string> {
    return this.encryptData(password);
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }

  decryptPassword(encryptedPassword): Promise<string> {
    return this.decryptData(encryptedPassword) as Promise<string>;
  }
}
