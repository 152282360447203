import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { PsDirectiveModule, PsDynamicFormModule } from '@ps-erp-angular/ps-ui';
import { Page404Component } from './page-feedbacks';
import { PsPageForgotPasswordComponent } from './page-forgot-password/page-forgot-password.component';
import { PsPageLoginComponent } from './page-login/page-login.component';
import { PsPageResetPasswordComponent } from './page-reset-password/ps-page-reset-password.component';
import { PsPageSelectModuleComponent } from './page-select-module/page-select-module.component';

import { LoadingComponent } from './loading/loading.component';
import { CPFPipe } from './page-select-module/cpf.pipe';
import { FilterPipe } from './page-select-module/filter.pipe';
import { AuthService } from './services/auth.service';
import { UrlService } from './services/url-server.service';

@NgModule({
  declarations: [
    PsPageLoginComponent,
    PsPageSelectModuleComponent,
    PsPageForgotPasswordComponent,
    PsPageResetPasswordComponent,
    Page404Component,
    FilterPipe,
    CPFPipe,
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    PsDirectiveModule,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzCardModule,
    NzFormModule,
    PsDynamicFormModule,
    NzIconModule,
    NzAvatarModule,
    NzToolTipModule,
    NzSwitchModule,
    NzSpinModule,
    NzSelectModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzDrawerModule,
    NzCalendarModule,
    NzDividerModule,
    NzListModule,
    NzCollapseModule,
    NzModalModule,
  ],
  exports: [
    PsPageLoginComponent,
    PsPageSelectModuleComponent,
    PsPageForgotPasswordComponent,
    PsPageResetPasswordComponent,
  ],
  providers: [FilterPipe, AuthService, UrlService, HttpClient],
})
export class PsPagesModule {}
