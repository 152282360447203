import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PsValidationFormService } from '@ps-erp-angular/ps-ui';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../environments/environment';
import { UrlService } from '../services/url-server.service';

@Component({
  selector: 'ps-acesso-page-forgot-password',
  templateUrl: './page-forgot-password.component.html',
  styleUrls: ['./page-forgot-password.scss'],
})
export class PsPageForgotPasswordComponent {
  today: Date = new Date();
  public urlConnection: string;

  constructor(
    private validationService: PsValidationFormService,
    private navigateRouter: Router,
    private location: Location,
    private http: HttpClient,
    private message: NzMessageService,
    urlServer: UrlService
  ) {
    this.urlConnection = urlServer.generateUrlByEnvironment(
      environment,
      '5010',
      'autorizacao-api'
    );
  }

  public model: any = {};
  public form: FormGroup = new FormGroup({});
  public isLoading = false;
  public fields: FormlyFieldConfig[] = [
    {
      fieldGroup: [
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            label: 'Email',
            placeholder: 'Email',
            required: true,
            attributes: {
              md: 24,
              xs: 24,
            },
          },
          validators: ['email'],
        },
      ],
    },
  ];

  reset(): void {
    this.form.reset();
    this.isLoading = false;
  }

  async submitForm(): Promise<any> {
    this.validationService.validateAllFormFields(this.form);

    this.isLoading = true;
    return await this.http
      .patch(`${this.urlConnection}/recuperar-senha`, {
        email: this.form.get('email').value,
      })
      .toPromise()
      .then((response) => {
        this.message.success(response[`message`], { nzDuration: 3500 });
        this.reset();
        this.navigateRouter.navigate(['/login']);
      })
      .catch((err) => {
        this.reset();
        this.message.error(err.error.message, { nzDuration: 2500 });
      });
  }

  back(): void {
    this.location.back();
  }
}
